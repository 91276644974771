import { t } from '@/i18next';

import {
  bulkChangeTokensStatus,
  bulkDeleteTokens,
  createToken,
  deleteToken,
  updateToken
} from '@/api/token';

import {
  BULK_CHANGE_TOKENS_STATUS_MODAL,
  BULK_DELETE_TOKENS_MODAL,
  CREATE_EDIT_TOKEN_MODAL,
  DELETE_ENTITY_MODAL,
  VIEW_TOKEN_MODAL
} from '@/constants/modals';

import { TOKENS_API } from '@/constants/api';

import type {
  BulkChangeTokensStatusParams,
  BulkDeleteTokensParams,
  CreateTokenParams,
  UpdateTokenParams
} from '@/api/token/types';

import type { ListToken } from '@/types/tokens';
import type { UseModal } from '@/types/modals';
import type { PromiseResolve } from '@/types/helpers';

export function openCreateTokenModal(openModal: UseModal['openModal']) {
  async function createTokenHandler(
    resolve: PromiseResolve,
    tokenParams: CreateTokenParams
  ) {
    const data = await createToken(tokenParams);

    resolve(data);
  }

  openModal<typeof CREATE_EDIT_TOKEN_MODAL>(CREATE_EDIT_TOKEN_MODAL, {
    confirmAction: createTokenHandler,
    useStatusConfirmation: true,
    invalidateQueryKey: TOKENS_API,
    successMessage: 'common.createdSuccessfully',
    allowGoBack: true
  });
}

export function openViewTokenModal(
  openModal: UseModal['openModal'],
  token: ListToken
) {
  async function onEditTokenHandler(resolve: PromiseResolve) {
    openEditTokenModal(openModal, token);

    resolve(false);
  }

  openModal<typeof VIEW_TOKEN_MODAL>(VIEW_TOKEN_MODAL, {
    confirmAction: onEditTokenHandler,
    invalidateQueryKey: TOKENS_API,
    useStatusConfirmation: false,
    token
  });
}

export function openDeleteTokenModal(
  openModal: UseModal['openModal'],
  token: ListToken
) {
  async function onDeleteTokenHandler(resolve: PromiseResolve) {
    const data = await deleteToken(token.id);
    resolve(data);
  }

  openModal<typeof DELETE_ENTITY_MODAL>(DELETE_ENTITY_MODAL, {
    confirmAction: onDeleteTokenHandler,
    useStatusConfirmation: true,
    invalidateQueryKey: TOKENS_API,
    deselectItems: token.id,
    name: token.name,
    confirmTranslation: 'common.delete',
    modalMessage: 'manageToken.delete_token',
    successMessage: 'common.deletedSuccessfully',
    successMessageParams: {
      entityType: t('manageTokens.token'),
      entityName: token.name
    }
  });
}

export function openEditTokenModal(
  openModal: UseModal['openModal'],
  token: ListToken
) {
  async function onEditTokenHandler(
    resolve: PromiseResolve,
    tokenParams: UpdateTokenParams
  ) {
    const data = await updateToken(tokenParams);
    resolve(data);
  }

  openModal<typeof CREATE_EDIT_TOKEN_MODAL>(CREATE_EDIT_TOKEN_MODAL, {
    confirmAction: onEditTokenHandler,
    useStatusConfirmation: true,
    allowGoBack: true,
    token: token,
    invalidateQueryKey: TOKENS_API,
    successMessage: 'common.updatedSuccessfully'
  });
}

export async function openBulkDeleteTokensModal(
  openModal: UseModal['openModal']
) {
  async function bulkDeleteHandler(
    resolve: PromiseResolve,
    bulkDeleteParams: BulkDeleteTokensParams
  ) {
    const data = await bulkDeleteTokens(bulkDeleteParams);
    resolve(data);
  }

  openModal<typeof BULK_DELETE_TOKENS_MODAL>(BULK_DELETE_TOKENS_MODAL, {
    confirmAction: bulkDeleteHandler,
    useStatusConfirmation: true,
    deselectItems: true,
    isBulkAction: true,
    invalidateQueryKey: TOKENS_API,
    successMessage: 'manageTokens.bulk_deletion_started'
  });
}

export async function openBulkChangeTokensStatusModal(
  openModal: UseModal['openModal'],
  active: boolean
) {
  async function bulkChangeStatusHandler(
    resolve: PromiseResolve,
    bulkChangeStatusParams: BulkChangeTokensStatusParams
  ) {
    const data = await bulkChangeTokensStatus(bulkChangeStatusParams);
    resolve(data);
  }

  openModal<typeof BULK_CHANGE_TOKENS_STATUS_MODAL>(
    BULK_CHANGE_TOKENS_STATUS_MODAL,
    {
      confirmAction: bulkChangeStatusHandler,
      active: active,
      useStatusConfirmation: true,
      isBulkAction: true,
      invalidateQueryKey: TOKENS_API,
      successMessage: active
        ? 'manageTokens.bulk_activation_started'
        : 'manageTokens.bulk_deactivation_started'
    }
  );
}
